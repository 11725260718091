import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Tag = styled.a`
  color: #fff;
  background-color: #061f5c;
  border-radius: 25px;
  font-size: 0.75rem;
  padding: 2px 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`

const TagComponent = ({ tag }) => {
  const href = `/tags/${tag}`

  return <Tag href={href}>{ tag }</Tag>
}

Tag.propTypes = {
  tag: PropTypes.string
}

export default TagComponent
