import React from "react"
import styled from "styled-components"

const Copyright = styled.div`
  color: #777;
  font-size: smaller;
`

const CopyrightComponent = () => {
  const duration = "2016-" + new Date().getFullYear()
  return (
    <Copyright>
      <span>&copy;{ duration }&nbsp;shimar</span>
    </Copyright>
  )
}

export default CopyrightComponent
