import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const TagList = styled.div`
  aling-items: center;
  display: flex;
`

const TagListComponent = ({ children }) => {
  return (
    <TagList>{children}</TagList>
  )
}

TagListComponent.propTypes = {
  children: PropTypes.node.isRequired
}

export default TagListComponent
