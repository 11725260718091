import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`

const PagingComponent = ({ currentPage, nextPath, prevPath, totalPages }) => {
  let prev = null
  let next = null
  if (currentPage > 1) {
    prev = <Link to={prevPath}>Prev</Link>
  }
  if (currentPage < totalPages) {
    next = <Link to={nextPath}>Next</Link>
  }

  return (
    <Paging>
      <div>{prev}</div>
      <div>{next}</div>
    </Paging>
  )
}

PagingComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  nextPath: PropTypes.string,
  prevPath: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
}

export default PagingComponent
