import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

const Ins = styled.ins`
  display: block;
`

const AdSense = ({ slot, format }) => {
  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  })

  return (
    <Ins
      className="adsbygoogle"
      data-ad-client="ca-pub-1457675049526339"
      data-ad-slot={slot}
      data-ad-format={format}
      data-fill-width-responsive="true"
    />
  )
}

AdSense.propTypes = {
  format: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
}

export default AdSense
