import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Seo } from "../components/atoms";
import { LayoutComponent } from "../components/organisms";

const Body = styled.div``
const Icons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`
const IconLink = styled.a`
  &:not(:last-child) {
    margin-right: 8px;
  }
`

const AboutPage = () => {
  return (
    <LayoutComponent hasTitle={true}>
      <h1>About</h1>
      <Body>
        <p>
          こんにちわ、フリーランスのエンジニア、shimarといいます。<br />
          1978年生まれ、神奈川県在住、2児の親です。
        </p>
        <p>
          最近は、RubyとRails、GoによるGraphQL、gRPCを使ったWebアプリケーションの開発に従事しています。
        </p>
        <p>このブログでは、主にWebやプログラミングなどについて、 個人的に調べたり実験したことを纏めていきます。</p>
        <p>
          何かのきっかけでこのサイトに訪れてくれた方のお役に立てれれば幸いです。<br />
          よろしくお願いします！
        </p>
      </Body>
      <Icons>
        <IconLink href="https://github.com/shimar">
          <FontAwesomeIcon icon={faGithub} size="lg" />
        </IconLink>
        <IconLink href="https://twitter.com/shima01xyz">
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </IconLink>
      </Icons>
    </LayoutComponent>
  )
}

export default AboutPage
export const Head = () => (
  <Seo title="About"
    pathname="/about" />
)
