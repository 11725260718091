import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { AdSense } from "../atoms"
import { FooterComponent, NavbarComponent } from "../molecules"

const Layout = styled.div`
  width: 100%;
`

const Content = styled.div`
  box-sizing: border-box;
  height: 100%;
  margin-left: 304px;
  margin-right: auto;
  position: relative;
  width: calc(100% - 304px);

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: auto;
  }
`

const Main = styled.div`
  padding: 1rem 4rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
`

const LayoutComponent = ({ children, hasTitle }) => {
  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  })

  return (
    <Layout>
      <NavbarComponent hasTitle={hasTitle} />
      <Content>
        <Main>
          {children}
        </Main>
        <AdSense slot="9469375783" format="auto" />
        <FooterComponent />
      </Content>
    </Layout>
  )
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  hasTitle: PropTypes.bool
}

export default LayoutComponent
