import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Navbar = styled.div`
  background-color: #030f2c;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
  position: fixed;
  width: 304px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 8px;
    position: relative;
    text-align: center;
    width: auto;
  }
`

const Title = styled.a`
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;

  h1,
  h2 {
    font-size: 2em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
`

const Links = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 10px 18px;

  a {
    color: #fff;
    margin: 6px 0;
    text-decoration: none;

    &__active {
      font-weight: bold;
    }
  }
`

const AdSenseContainer = styled.div`
  margin: 2rem auto;
`

const Ins = styled.ins`
  display: inline-block;
  height: 240px;
  width: 240px;
`

const NavbarComponent = ({ hasTitle }) => {
  let title = <h1>shimar's blog</h1>
  if (hasTitle) {
    title = <h2>shimar's blog</h2>
  }

  const links = [
    {
      href: "/",
      caption: "Home",
    },
    {
      href: "/about",
      caption: "About",
    },
    {
      href: "/privacy",
      caption: "Privacy Policy",
    },
  ]

  return <Navbar>
    <Title href="/">{title}</Title>
    <Links>
      {links.map(link => {
        return <Link key={link.caption} to={link.href}>{link.caption}</Link>
      })}
    </Links>
    <AdSenseContainer>
      <Ins
        className="adsbygoogle"
        data-ad-client="ca-pub-1457675049526339"
        data-ad-slot="9087599388"
      />
    </AdSenseContainer>
  </Navbar>
}

Navbar.propTypes = {
  hasTitle: PropTypes.bool
}

export default NavbarComponent
