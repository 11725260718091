import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import styled from "styled-components"

const Title = styled.h1``

const TitleComponent = ({ title, href }) => {
  return (
    <Link to={href}>
      <Title>{title}</Title>
    </Link>
  )
}

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}

export default TitleComponent
