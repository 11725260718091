import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { TagComponent } from "../atoms"

const Post = styled.div`
  padding: 1.5rem 0;

  &:not(:first-child) {
    border-top: solid 1px #ddd;
  }
`

const PublishedAt = styled.div`
  font-size: small;
  margin: 0 0 12px;
`

const PostContent = styled.div`
  h2 {
    margin: 0;
    padding: 0;
  }
`

const Tags = styled.div`
  aling-items: center;
  display: flex;
  margin: 12px 0 0;
`

const PostComponent = ({ title, canonical, tags, publishedAt }) => {
  const tagComponents = tags.map((tag, idx) => <TagComponent key={idx} tag={tag} />)
  return <Post>
    <PublishedAt>{publishedAt}</PublishedAt>
    <PostContent>
      <Link to={canonical}>
        <h2>{title}</h2>
      </Link>
      <Tags>
        {tagComponents}
      </Tags>
    </PostContent>
  </Post>
}

PostComponent.propTypes = {
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  tags: PropTypes.array,
  publishedAt: PropTypes.string.isRequired,
}

export default PostComponent
