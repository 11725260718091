import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const AffiliateItem = styled.div`
  display: flex;
  justify-content: center;
`

const Item = styled.div``

const AffiliateItemComponent = ({ item }) => {
  return (
    <AffiliateItem>
      <Item dangerouslySetInnerHTML={{ __html: item }} />
    </AffiliateItem>
  )
}

AffiliateItemComponent.prototype = {
  item: PropTypes.string.isRequired
}

export default AffiliateItemComponent
