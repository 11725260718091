import React from "react"
import styled from "styled-components"
import { CopyrightComponent } from "../atoms"

const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 0 1rem;
`

const FooterComponent = () => {
  return (
    <Footer>
      <CopyrightComponent />
    </Footer>
  )
}

export default FooterComponent
